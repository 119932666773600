import React, { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';

function HeaderMobileHome({
  handleActiveAside,
  handleActiveCart,
  activeAside,
  handleCloseAside,
}) {
  const navigate = useNavigate();

  return (
    <Fragment>
      <div className="header-mobile-home-app">
        <div
          className="card-cart-products span-header-top-mobile"
          onClick={handleActiveAside}
        >
          <img
            className="img-card-cart-products"
            src={require('../images/barras.png')}
            alt="cart"
          />
        </div>
        <div>
          <div className="profile-brand">
            <img
              className="img-ptofile-brand"
              src={require('../images/logo.png')}
              alt="logo"
              onClick={() => navigate('/')}
            />
          </div>
        </div>
        <div className="card-cart-products" onClick={handleActiveCart}>
          <img
            className="img-card-cart-products"
            src={require('../images/cart.png')}
            alt="cart"
          />
        </div>
      </div>

      <div
        className={`aside-header ${activeAside ? 'aside-header-active' : ''}`}
      >
        <img
          className="img-close-cart"
          src={require('../images/close.png')}
          onClick={handleCloseAside}
          alt="cerrar"
        />
        <h1 className="title-top-aside">Nuestros Artículos</h1>
        <li
          className="item-aside-header"
          onClick={() => navigate('/productos/categoria/Todos%20los%20artículos')}
        >
          Todos los productos
        </li>
        <li
          className="item-aside-header"
          onClick={() => navigate('/productos/categoria/Buzos')}
        >
          Buzos
        </li>
        <li
          className="item-aside-header"
          onClick={() => navigate('/productos/categoria/Camperas')}
        >
          Camperas
        </li>
        <li
          className="item-aside-header"
          onClick={() => navigate('/productos/categoria/Remeras')}
        >
          Remeras
        </li>
        <li
          className="item-aside-header"
          onClick={() => navigate('/productos/categoria/Zapatillas')}
        >
          Zapatillas
        </li>
        <h1 className="title-top-aside">Más Información</h1>
        <li
          className="item-aside-header"
          onClick={() => navigate('/nosotros')}
        >
          Sobre Nosotros
        </li>
        <li
          className="item-aside-header"
          onClick={() => navigate('/compras-mayoristas')}
        >
          Compras Mayoristas
        </li>
        <li
          className="item-aside-header"
          onClick={() => navigate('/preguntas-frecuentes')}
        >
          Preguntas Fecuentes 
        </li>
        <li
          className="item-aside-header"
          onClick={() => navigate('/productos/categoria/Zapatillas')}
        >
          Contactanos
        </li>
      </div>
    </Fragment>
  );
}

export default HeaderMobileHome;
