import React, { useState } from 'react';
import axios from 'axios';

function DashboardProductDetails({ product, onClose, onDelete, onUpdate }) {
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({
    imagenes: product.imagenes || [],
    nombre: product.nombre,
    precio: product.precio,
    descripcion: product.descripcion,
    talles: product.talles || [],
    colores: product.colores || [],
  });
  const [newImage, setNewImage] = useState('');
  const [error, setError] = useState('');

  if (!product) return null;

  const handleDelete = async () => {
    try {
      const response = await axios.delete(
        `https://ecommerce-backend-phi-seven.vercel.app/productos/${product.id}`,
      );
      if (response.status === 200) {
        alert('Producto eliminado');
        onDelete();
      } else {
        throw new Error('Error al eliminar el producto');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Error al eliminar el producto');
    }
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleTalleChange = (index, e) => {
    const { name, value } = e.target;
    const newTalles = [...formData.talles];
    newTalles[index] = {
      ...newTalles[index],
      [name]: value,
    };
    setFormData({
      ...formData,
      talles: newTalles,
    });
  };

  const handleColorChange = (index, e) => {
    const { name, value } = e.target;
    const newColores = [...formData.colores];
    newColores[index] = {
      ...newColores[index],
      [name]: value,
    };
    setFormData({
      ...formData,
      colores: newColores,
    });
  };

  const handleAddTalle = () => {
    setFormData({
      ...formData,
      talles: [...formData.talles, { talle: '', stock: 0 }],
    });
  };

  const handleAddColor = () => {
    setFormData({
      ...formData,
      colores: [...formData.colores, { color: '', stock: 0 }],
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put(
        `https://ecommerce-backend-phi-seven.vercel.app/productos/${product.id}`,
        formData,
      );
      if (response.status === 200) {
        alert('Producto actualizado');
        onUpdate();
        setIsEditing(false);
      } else {
        throw new Error('Error al actualizar el producto');
      }
    } catch (error) {
      console.error('Error:', error);
      setError('Error al actualizar el producto');
    }
  };

  const handleAddImage = () => {
    if (newImage) {
      setFormData({
        ...formData,
        imagenes: [...formData.imagenes, newImage],
      });
      setNewImage('');
    }
  };

  const handleRemoveImage = (imageToRemove) => {
    setFormData({
      ...formData,
      imagenes: formData.imagenes.filter((img) => img !== imageToRemove),
    });
  };

  const handleRemoveTalle = (index) => {
    const newTalles = formData.talles.filter((_, i) => i !== index);
    setFormData({
      ...formData,
      talles: newTalles,
    });
  };
  
  const handleRemoveColor = (index) => {
    const newColores = formData.colores.filter((_, i) => i !== index);
    setFormData({
      ...formData,
      colores: newColores,
    });
  };


  const handleChangeStatus = async (newStatus) => {
    try {
      const response = await axios.put(
        `https://ecommerce-backend-phi-seven.vercel.app/productos/${product.id}`,
        { ...product, status: newStatus }
      );
      if (response.status === 200) {
        alert(`Producto ${newStatus ? 'activado' : 'desactivado'}`);
        onUpdate(); // Actualiza la vista del producto
      } else {
        throw new Error('Error al actualizar el estado del producto');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Error al actualizar el estado del producto');
    }
  };

  return (
    <div className="product-details">
      {isEditing ? (
        <div className="product-edit">
          <h2>Editar Producto</h2>
          {error && <p className="error">{error}</p>}
          <form onSubmit={handleSubmit}>
            <div>
              <label>Imagenes URLs (separadas por coma):</label>
              <input
                type="text"
                name="imagenes"
                value={formData.imagenes.join(', ')}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    imagenes: e.target.value
                      .split(',')
                      .map((url) => url.trim()),
                  });
                }}
              />
            </div>
            <div>
              <label>Agregar nueva imagen URL:</label>
              <input
                type="text"
                value={newImage}
                onChange={(e) => setNewImage(e.target.value)}
              />
              <button
                type="button"
                onClick={handleAddImage}
                className="btn-sales-comp"
              >
                Agregar Imagen
              </button>
            </div>
            <div className="image-gallery">
              {formData.imagenes.map((img, index) => (
                <div key={index} className="image-item">
                  <img
                    src={img}
                    alt={`Imagen ${index + 1}`}
                    width="100"
                    style={{ margin: '10px' }}
                  />
                  <button
                    type="button"
                    onClick={() => handleRemoveImage(img)}
                    className="btn-sales-comp"
                    style={{ marginLeft: '10px' }}
                  >
                    Eliminar
                  </button>
                </div>
              ))}
            </div>
            <div>
              <label>Nombre:</label>
              <input
                type="text"
                name="nombre"
                value={formData.nombre}
                onChange={handleChange}
              />
            </div>
            <div>
              <label>Precio:</label>
              <input
                type="number"
                name="precio"
                value={formData.precio}
                onChange={handleChange}
              />
            </div>
            <div>
              <label>Descripción:</label>
              <textarea
                name="descripcion"
                value={formData.descripcion}
                onChange={handleChange}
              />
            </div>
            {formData.talles.map((talle, index) => (
              <div key={index}>
                <label>Talle:</label>
                <input
                  type="text"
                  name="talle"
                  value={talle.talle}
                  onChange={(e) => handleTalleChange(index, e)}
                />
                <label>Stock:</label>
                <input
                  type="number"
                  name="stock"
                  value={talle.stock}
                  onChange={(e) => handleTalleChange(index, e)}
                />
                 <button
                type="button"
                onClick={() => handleRemoveTalle(index)}
                className="btn-sales-comp"
              >
                ➡ Eliminar
              </button>
              </div>
            ))}
            <button type="button" onClick={handleAddTalle} className="btn-sales-comp">
              Agregar Talle
            </button>

            {formData.colores.map((color, index) => (
              <div key={index}>
                <label>Color:</label>
                <input
                  type="text"
                  name="color"
                  value={color.color}
                  onChange={(e) => handleColorChange(index, e)}
                />
                <label>Stock:</label>
                <input
                  type="number"
                  name="stock"
                  value={color.stock}
                  onChange={(e) => handleColorChange(index, e)}
                />
                 <button
                type="button"
                onClick={() => handleRemoveColor(index)}
                className="btn-sales-comp"
              >
                ➡ Eliminar
              </button>
              </div>
            ))}

            <button type="button" onClick={handleAddColor} className="btn-sales-comp">
              Agregar Color
            </button>

            <button className="btn-sales-comp" type="submit">
              Actualizar
            </button>
            <button
              className="btn-sales-comp"
              type="button"
              onClick={() => setIsEditing(false)}
            >
              Cancelar
            </button>
            <button className="btn-sales-comp" onClick={onClose}>
            Cerrar
          </button>
          </form>
        </div>
      ) : (
        <>
          <h2>{product.nombre}</h2>
          <div className="image-gallery">
            {formData.imagenes.map((img, index) => (
              <img
                key={index}
                src={img}
                alt={`Imagen ${index + 1}`}
                width="200"
                style={{ margin: '10px' }}
              />
            ))}
          </div>
          <p>
            <strong>Precio:</strong> {product.precio}
          </p>
          <p>
            <strong>Descripción:</strong> {product.descripcion}
          </p>
          <p>
          <strong>Estado del producto:</strong> {product.status ? 'Activo' : 'Inactivo'}
          </p>

          <div className="container-table-dp">
            <table className="table">
              <thead>
                <tr>
                  <th>Talles</th>
                  <th>Stock</th>
                </tr>
              </thead>
              <tbody>
                {product.talles.map((talle) => (
                  <tr key={talle.id}>
                    <td>{talle.talle}</td>
                    <td>{talle.stock}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="container-table-dp">
            <table className="table">
              <thead>
                <tr>
                  <th>Colores</th>
                  <th>Stock</th>
                </tr>
              </thead>
              <tbody>
                {product.colores.map((color) => (
                  <tr key={color.id}>
                    <td>{color.color}</td>
                    <td>{color.stock}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <button className="btn-sales-comp" onClick={handleEdit}>
            Editar Producto
          </button>
          <button className="btn-sales-comp" onClick={handleDelete}>
            Eliminar Producto
          </button>
          {product.status ? (
  <button className="btn-sales-comp" onClick={() => handleChangeStatus(false)}>
    Dar de baja
  </button>
) : (
  <button className="btn-sales-comp" onClick={() => handleChangeStatus(true)}>
    Dar de alta
  </button>
)}
          <button className="btn-sales-comp" onClick={onClose}>
            Cerrar
          </button>
        </>
      )}
    </div>
  );
}

export default DashboardProductDetails;
