import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';

const ModalAddVenta = ({ show, handleClose, handleShowBanner }) => {
  const [nombre, setNombre] = useState('');
  const [apellido, setApellido] = useState('');
  const [fechaHora, setFechaHora] = useState('');
  const [estado, setEstado] = useState('');
  const [total, setTotal] = useState('');
  const [productos, setProductos] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchProductos = async () => {
      try {
        const response = await axios.get(
          'https://ecommerce-backend-phi-seven.vercel.app/productos',
        );
        setProductos(response.data);
      } catch (error) {
        setError('Error al cargar los productos');
      }
    };

    fetchProductos();
  }, []);

  const handleProductChange = (index, field, value) => {
    setSelectedProducts((prev) => {
      const newSelectedProducts = [...prev];
      newSelectedProducts[index] = {
        ...newSelectedProducts[index],
        [field]: value,
      };
      return newSelectedProducts;
    });
  };

  const addProductField = () => {
    setSelectedProducts((prev) => [
      ...prev,
      { id: '', cantidad: 1, color: '', talle: '' },
    ]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    try {
      const newSale = {
        nombre,
        apellido,
        fechaHora,
        estado,
        total: parseFloat(total),
        productos: selectedProducts,
      };

      await axios.post(
        'https://ecommerce-backend-phi-seven.vercel.app/venta-nueva',
        newSale,
      );
      handleShowBanner('Venta agregada con éxito');
      handleClose();
    } catch (error) {
      setError('Error al agregar la venta');
      console.error('Error adding sale:', error);
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Agregar Venta</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formNombre">
            <Form.Label>Nombre</Form.Label>
            <Form.Control
              type="text"
              placeholder="Ingrese nombre del cliente"
              value={nombre}
              onChange={(e) => setNombre(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group controlId="formApellido">
            <Form.Label>Apellido</Form.Label>
            <Form.Control
              type="text"
              placeholder="Ingrese apellido del cliente"
              value={apellido}
              onChange={(e) => setApellido(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group controlId="formFechaHora">
            <Form.Label>Fecha y Hora</Form.Label>
            <Form.Control
              type="datetime-local"
              value={fechaHora}
              onChange={(e) => setFechaHora(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group controlId="formEstado">
            <Form.Label>Estado</Form.Label>
            <Form.Control
              as="select"
              value={estado}
              onChange={(e) => setEstado(e.target.value)}
              required
            >
              <option value="">Seleccione estado</option>
              <option value="en curso">en curso</option>
              <option value="finalizada">finalizada</option>
              <option value="cancelada">cancelada</option>
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="formTotal">
            <Form.Label>Total</Form.Label>
            <Form.Control
              type="number"
              step="0.01"
              placeholder="Ingrese total de la venta"
              value={total}
              onChange={(e) => setTotal(e.target.value)}
              required
            />
          </Form.Group>
          {selectedProducts.map((selectedProduct, index) => {
            const producto =
              productos.find((p) => p.id === selectedProduct.id) || {};
            const colores = Array.isArray(producto.colores)
              ? producto.colores.map((c) => c.color)
              : [];
            const talles = Array.isArray(producto.talles)
              ? producto.talles.map((t) => t.talle)
              : [];

            return (
              <div key={index} className="mb-3">
                <Row>
                  <Col md={4}>
                    <Form.Group controlId={`formProduct-${index}`}>
                      <Form.Label>Producto</Form.Label>
                      <Form.Control
                        as="select"
                        value={selectedProduct.id}
                        onChange={(e) =>
                          handleProductChange(index, 'id', e.target.value)
                        }
                        required
                      >
                        <option value="">Seleccione producto</option>
                        {productos.map((producto) => (
                          <option key={producto.id} value={producto.id}>
                            {producto.nombre} - ${producto.precio}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md={2}>
                    <Form.Group controlId={`formCantidad-${index}`}>
                      <Form.Label>Cantidad</Form.Label>
                      <Form.Control
                        type="number"
                        value={selectedProduct.cantidad}
                        onChange={(e) =>
                          handleProductChange(index, 'cantidad', e.target.value)
                        }
                        min="1"
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col md={3}>
                    <Form.Group controlId={`formColor-${index}`}>
                      <Form.Label>Color</Form.Label>
                      <Form.Control
                        as="select"
                        value={selectedProduct.color}
                        onChange={(e) =>
                          handleProductChange(index, 'color', e.target.value)
                        }
                        required
                      >
                        <option value="">Seleccione color</option>
                        {colores.map((color, i) => (
                          <option key={i} value={color}>
                            {color}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md={3}>
                    <Form.Group controlId={`formTalle-${index}`}>
                      <Form.Label>Talle</Form.Label>
                      <Form.Control
                        as="select"
                        value={selectedProduct.talle}
                        onChange={(e) =>
                          handleProductChange(index, 'talle', e.target.value)
                        }
                        required
                      >
                        <option value="">Seleccione talle</option>
                        {talles.map((talle, i) => (
                          <option key={i} value={talle}>
                            {talle}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                </Row>
              </div>
            );
          })}
          <Button variant="secondary span-ventas-btn" onClick={addProductField}>
            Añadir otro producto
          </Button>
          {error && <p className="text-danger mt-2">{error}</p>}
          <Button variant="primary" type="submit" className="mt-3">
            Agregar Venta
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ModalAddVenta;
