import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import '../styles/nosotros.css';
import { Pagination, Navigation } from 'swiper/modules';
import Footer from '../comp/footer';
import { useNavigate } from 'react-router-dom';
import Carrito from '../comp/carrito';
import HeaderMobileHome from '../comp/headerMobileHome';

const Terminos = () => {
  const [activeCartShow, setActiveCartShow] = useState(false);
  const [activeAside, setActiveAside] = useState(false);
  const [terminos, setTerminos] = useState('');

  const handleActiveCart = () => {
    setActiveCartShow(true);
  };

  const handleCloseCart = () => {
    setActiveCartShow(false);
  };

  const navigate = useNavigate();

  const handleActiveAside = () => {
    setActiveAside(true);
  };

  const handleCloseAside = () => {
    setActiveAside(false);
  };

  useEffect(() => {
    const fetchTerminos = async () => {
      try {
        const response = await axios.get('https://ecommerce-backend-phi-seven.vercel.app/config');
        setTerminos(response.data.terminos); 
      } catch (error) {
        console.error('Error al cargar los términos y condiciones:', error);
      }
    };
    fetchTerminos();
  }, []);

  return (
    <div className="nosotros-container">
      <Carrito activeCart={activeCartShow} handleCloseCart={handleCloseCart} />
      <HeaderMobileHome
        handleActiveCart={handleActiveCart}
        activeAside={activeAside}
        handleActiveAside={handleActiveAside}
        handleCloseAside={handleCloseAside}
      />

      <div className="header-top-banner-products">
        <h1 className="text-inside-banner-products">
          El envío es gratis a partir de tu compra de 12 unidades en artículos
        </h1>
      </div>

      <header className="header-general-products">
        <img
          className="img-ptofile-brand"
          src={require('../images/logo.png')}
          onClick={() => navigate('/')}
          alt="logo"
        />

        <input
          className="input-header-search-products"
          type="text"
          placeholder="Buscar productos"
        />

        <div className="container-row-header-options">
          <li className="option-header" onClick={() => navigate('/productos')}>
            Articulos
          </li>
          <li className="option-header" onClick={() => navigate('/productos')}>
            Categorías
          </li>
          <li className="option-header" onClick={() => navigate('/compras-mayoristas')}>
            Compras Mayoristas
          </li>
        </div>

        <div className="card-cart-products" onClick={handleActiveCart}>
          <img
            className="img-card-cart-products"
            src={require('../images/cart.png')}
            alt="cart"
          />
        </div>
      </header>

      <section className="nosotros-banner">
        <div className="banner-content">
          <h1 className="banner-title">Nuestros términos y condiciones</h1>
          <p className="banner-subtitle">Detallamos paso a paso lo que debes saber</p>
        </div>
      </section>

      <section className="nosotros-info">
        <div className="container">
          <h2 className="info-title">¿Cómo funciona la web?</h2>
          <p className="info-description">
            {terminos ? terminos : 'Cargando términos y condiciones...'}
          </p>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default Terminos;
