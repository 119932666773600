import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import ProductoArtSwiper from './productoArtSwiper';

const Carousel = ({
  products,
  agregarAlCarrito,
  handleShowBanner,
  onProductoClick,
}) => {
  return (
    <Swiper
      modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
      spaceBetween={1}
      slidesPerView={4}
      navigation
      pagination={{ clickable: true }}
      scrollbar={{ draggable: true }}
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
      }}
      breakpoints={{
        320: {
          slidesPerView: 2,
          spaceBetween: 0.1,
        },
        640: {
          slidesPerView: 2,
          spaceBetween: 1,
        },
        768: {
          slidesPerView: 3,
          spaceBetween: 1,
        },
        1024: {
          slidesPerView: 4,
          spaceBetween: 1,
        },
      }}
    >
      {products.map((producto) => (
        <SwiperSlide key={producto.id}>
          <ProductoArtSwiper
            producto={producto}
            agregarAlCarrito={agregarAlCarrito}
            handleShowBanner={handleShowBanner}
            onProductoClick={onProductoClick}
          />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default Carousel;
