import React, { Fragment, useEffect, useState } from 'react';
import axios from 'axios';
import { Button } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import BannerDash from './bannerDash';
import { motion } from 'framer-motion';

function FinanzasComp({ formattedDate }) {
  const [ventasDiarias, setVentasDiarias] = useState(0);
  const [historialVentas, setHistorialVentas] = useState([]);

  useEffect(() => {
    const obtenerHistorial = async () => {
      try {
        const response = await axios.get('https://ecommerce-backend-phi-seven.vercel.app/registros-dia');
        setHistorialVentas(response.data); 
      } catch (error) {
        console.error('Error obteniendo el historial de ventas:', error);
      }
    };

    obtenerHistorial();
  }, []);

  useEffect(() => {
    const obtenerVentas = async () => {
      try {
        const response = await axios.get('https://ecommerce-backend-phi-seven.vercel.app/ventas');
        const ventasEnCurso = response.data.filter(venta => 
          venta.estado === 'en curso' && 
          new Date(venta.fechaHora).toLocaleDateString() === new Date().toLocaleDateString()
        );
        const totalVentasEnCurso = ventasEnCurso.reduce((total, venta) => total + venta.total, 0);
        setVentasDiarias(totalVentasEnCurso);
      } catch (error) {
        console.error('Error obteniendo ventas:', error);
      }
    };

    obtenerVentas();

    
    const intervalId = setInterval(() => {
      const now = new Date();
      if (now.getHours() === 23 && now.getMinutes() === 59) {
        guardarRegistroDelDia();
      }
    }, 60000);

    return () => clearInterval(intervalId);
  }, [formattedDate]);

  const guardarRegistroDelDia = async () => {
    const nuevoRegistro = {
      fecha: formattedDate,
      monto: ventasDiarias,
    };

    try {
      const response = await axios.post('https://ecommerce-backend-phi-seven.vercel.app/registros-dia', nuevoRegistro);
      if (response.status === 201) {
        setHistorialVentas(prevHistorial => [...prevHistorial, nuevoRegistro]); 
        setVentasDiarias(0);
      }
    } catch (error) {
      console.error('Error guardando el registro del día:', error);
    }
  };

  return (
    <Fragment>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.15 }}
      >
        <div className="container-web-viewport">
          <div>
            <BannerDash
              title="Finanzas"
              formattedDate={formattedDate}
              text="Dashboard"
            />
          </div>
          <div className="ventas-diarias">
            <h3>Ventas del día: {ventasDiarias.toLocaleString()}</h3>
          </div>
          <div className="historial-ventas">
            <h4>Historial de Ventas</h4>
            <ul>
              {historialVentas.map((venta, index) => (
                <li key={index}>
                  Fecha: {venta.fecha} - Monto: {venta.monto}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </motion.div>
    </Fragment>
  );
}

export default FinanzasComp;
