import React, { useState, useEffect } from 'react';
import axios from 'axios';
import BannerDash from './bannerDash';
import CardDashComp from './cardDashComp';

const ConfigComp = ({ formattedDate }) => {
  const [texto, setTexto] = useState('');
  const [texto2, setTexto2] = useState('');
  const [terminos, setTerminos] = useState('');
  const [nuevaPregunta, setNuevaPregunta] = useState('');
  const [nuevaRespuesta, setNuevaRespuesta] = useState('');
  const [preguntas, setPreguntas] = useState([]);
  const [editandoId, setEditandoId] = useState(null);
  const [editandoPregunta, setEditandoPregunta] = useState('');
  const [editandoRespuesta, setEditandoRespuesta] = useState('');
  const [nuevoTexto, setNuevoTexto] = useState('');
  const [nuevoTexto2, setNuevoTexto2] = useState('');
  const [nuevoTerminos, setNuevoTerminos] = useState('');
  const [loadingTextos, setLoadingTextos] = useState(false);
  const [loadingTerminos, setLoadingTerminos] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [activeSection, setActiveSection] = useState(null); // Para controlar la sección activa

  useEffect(() => {
    // Fetch config texts and FAQs on mount
    const fetchConfigData = async () => {
      try {
        const response = await axios.get('https://ecommerce-backend-phi-seven.vercel.app/config');
        setTexto(response.data.texto);
        setTexto2(response.data.texto2);
        setTerminos(response.data.terminos);
      } catch (error) {
        console.error('Error al cargar los textos:', error);
      }
    };

    const fetchPreguntas = async () => {
      try {
        const response = await axios.get('https://ecommerce-backend-phi-seven.vercel.app/faqs');
        setPreguntas(response.data);
      } catch (error) {
        console.error('Error al cargar preguntas frecuentes:', error);
      }
    };

    fetchConfigData();
    fetchPreguntas();
  }, []);

  // Handling for Textos Banner
  const handleChangeTextos = (e) => {
    if (e.target.id === 'texto') {
      setNuevoTexto(e.target.value);
    } else if (e.target.id === 'texto2') {
      setNuevoTexto2(e.target.value);
    }
  };

  const handleSubmitTextos = async (e) => {
    e.preventDefault();
    setLoadingTextos(true);

    const dataToSend = {};
    if (nuevoTexto !== texto && nuevoTexto) {
      dataToSend.texto = nuevoTexto;
    }
    if (nuevoTexto2 !== texto2 && nuevoTexto2) {
      dataToSend.texto2 = nuevoTexto2;
    }

    if (Object.keys(dataToSend).length === 0) {
      setSuccessMessage('No se han realizado cambios en los textos');
      setLoadingTextos(false);
      return;
    }

    try {
      await axios.put('https://ecommerce-backend-phi-seven.vercel.app/config', dataToSend);
      setSuccessMessage('Textos actualizados correctamente');
      if (dataToSend.texto) setTexto(nuevoTexto);
      if (dataToSend.texto2) setTexto2(nuevoTexto2);
    } catch (error) {
      console.error('Error al actualizar los textos:', error);
      setSuccessMessage('Hubo un error al actualizar los textos');
    } finally {
      setLoadingTextos(false);
    }
  };

  // Handling for Términos y Condiciones
  const handleChangeTerminos = (e) => {
    setNuevoTerminos(e.target.value);
  };

  const handleSubmitTerminos = async (e) => {
    e.preventDefault();
    setLoadingTerminos(true);

    if (nuevoTerminos === terminos || !nuevoTerminos) {
      setSuccessMessage('No se han realizado cambios en los términos y condiciones');
      setLoadingTerminos(false);
      return;
    }

    const dataToSend = { terminos: nuevoTerminos };

    try {
      await axios.put('https://ecommerce-backend-phi-seven.vercel.app/config', dataToSend);
      setSuccessMessage('Términos y condiciones actualizados correctamente');
      setTerminos(nuevoTerminos);
    } catch (error) {
      console.error('Error al actualizar los términos y condiciones:', error);
      setSuccessMessage('Hubo un error al actualizar los términos y condiciones');
    } finally {
      setLoadingTerminos(false);
    }
  };

  // Handling for Preguntas Frecuentes
  const handleAgregarPregunta = async (e) => {
    e.preventDefault();
    if (nuevaPregunta && nuevaRespuesta) {
      try {
        const response = await axios.post('https://ecommerce-backend-phi-seven.vercel.app/faqs', {
          pregunta: nuevaPregunta,
          respuesta: nuevaRespuesta,
        });
        setPreguntas([...preguntas, response.data]);
        setNuevaPregunta('');
        setNuevaRespuesta('');
      } catch (error) {
        console.error('Error al agregar la pregunta frecuente:', error);
      }
    }
  };

  const handleEditarPregunta = async (id) => {
    if (editandoPregunta && editandoRespuesta) {
      try {
        const response = await axios.put(`https://ecommerce-backend-phi-seven.vercel.app/faqs/${id}`, {
          pregunta: editandoPregunta,
          respuesta: editandoRespuesta,
        });
        const preguntasActualizadas = preguntas.map((pregunta) =>
          pregunta.id === id ? response.data : pregunta
        );
        setPreguntas(preguntasActualizadas);
        setEditandoId(null);
        setEditandoPregunta('');
        setEditandoRespuesta('');
      } catch (error) {
        console.error('Error al editar la pregunta frecuente:', error);
      }
    }
  };

  const handleEliminarPregunta = async (id) => {
    try {
      await axios.delete(`https://ecommerce-backend-phi-seven.vercel.app/faqs/${id}`);
      setPreguntas(preguntas.filter((pregunta) => pregunta.id !== id));
    } catch (error) {
      console.error('Error al eliminar la pregunta frecuente:', error);
    }
  };

  return (
    <div>
      <BannerDash
        title="Configuración de textos"
        formattedDate={formattedDate}
        text="Dashboard"
      />
      <div className="container">
        <div className='row'>
          <div className='col-xl-4 col-lg-4 col-md-6' onClick={() => setActiveSection('textos')} style={{ cursor: 'pointer' }}>
            <CardDashComp title="Textos Banner" />
          </div>
          <div className='col-xl-4 col-lg-4 col-md-6' onClick={() => setActiveSection('terminos')} style={{ cursor: 'pointer' }}>
            <CardDashComp title="Términos y condiciones" />
          </div>
          <div className='col-xl-4 col-lg-4 col-md-6' onClick={() => setActiveSection('faqs')} style={{ cursor: 'pointer' }}>
            <CardDashComp title="Preguntas Frecuentes" />
          </div>
        </div>
      </div>

      {/* Mostrar Textos Banner */}
      {activeSection === 'textos' && (
        <div className="config-comp">
          <h2>Configurar Textos</h2>
          <p>Texto banner principal: {texto}</p>
          <p>Texto banner fondo negro (adicional): {texto2}</p>
          <form onSubmit={handleSubmitTextos}>
            <label htmlFor="texto">Nuevo Texto 1:</label>
            <input
              type="text"
              id="texto"
              value={nuevoTexto}
              onChange={handleChangeTextos}
              disabled={loadingTextos}
              placeholder={texto}
            />
            <label htmlFor="texto2">Nuevo Texto 2:</label>
            <input
              type="text"
              id="texto2"
              value={nuevoTexto2}
              onChange={handleChangeTextos}
              disabled={loadingTextos}
              placeholder={texto2}
            />
            <button className="btn-config-texts" type="submit" disabled={loadingTextos}>
              {loadingTextos ? 'Guardando...' : 'Guardar Textos'}
            </button>
          </form>
        </div>
      )}

      {/* Mostrar Términos y Condiciones */}
      {activeSection === 'terminos' && (
        <div className="config-comp">
          <h3>Modificar Términos y Condiciones</h3>
          <p>Texto actual de Términos y Condiciones: {terminos}</p>
          <form onSubmit={handleSubmitTerminos}>
            <label htmlFor="terminos">Nuevo Texto de Términos y Condiciones:</label>
            <input
              type="text"
              id="terminos"
              value={nuevoTerminos}
              onChange={handleChangeTerminos}
              disabled={loadingTerminos}
              placeholder={terminos}
            />
            <button className="btn-config-texts" type="submit" disabled={loadingTerminos}>
              {loadingTerminos ? 'Guardando...' : 'Guardar Términos'}
            </button>
          </form>
        </div>
      )}

      {activeSection === 'faqs' && (
        <div className="config-comp">
          <h2>Preguntas Frecuentes</h2>
          <form onSubmit={handleAgregarPregunta}>
            <label htmlFor="nuevaPregunta">Nueva Pregunta:</label>
            <input
              type="text"
              id="nuevaPregunta"
              value={nuevaPregunta}
              onChange={(e) => setNuevaPregunta(e.target.value)}
            />
            <label htmlFor="nuevaRespuesta">Nueva Respuesta:</label>
            <input
              type="text"
              id="nuevaRespuesta"
              value={nuevaRespuesta}
              onChange={(e) => setNuevaRespuesta(e.target.value)}
            />
            <button className='btn-config-texts' type="submit">Agregar Pregunta</button>
          </form>

          <div className="lista-preguntas">
            {preguntas.map((pregunta) => (
              <div key={pregunta.id} className="pregunta-item">
                {editandoId === pregunta.id ? (
                  <div>
                    <input
                      type="text"
                      value={editandoPregunta}
                      onChange={(e) => setEditandoPregunta(e.target.value)}
                      placeholder="Editar pregunta"
                    />
                    <input
                      type="text"
                      value={editandoRespuesta}
                      onChange={(e) => setEditandoRespuesta(e.target.value)}
                      placeholder="Editar respuesta"
                    />
                    <button onClick={() => handleEditarPregunta(pregunta.id)}>Guardar</button>
                    <button onClick={() => setEditandoId(null)}>Cancelar</button>
                  </div>
                ) : (
                  <div>
                    <p><strong>Pregunta:</strong> {pregunta.pregunta}</p>
                    <p><strong>Respuesta:</strong> {pregunta.respuesta}</p>
                    <button onClick={() => {
                      setEditandoId(pregunta.id);
                      setEditandoPregunta(pregunta.pregunta);
                      setEditandoRespuesta(pregunta.respuesta);
                    }}>
                      Editar
                    </button>
                    <button onClick={() => handleEliminarPregunta(pregunta.id)}>Eliminar</button>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      )}

      {successMessage && <p>{successMessage}</p>}
    </div>
  );
};

export default ConfigComp;
