import React, { useState, useEffect, Fragment, useRef } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { useCarrito } from '../services/carritoController';
import 'react-loading-skeleton/dist/skeleton.css';
import Skeleton from 'react-loading-skeleton';
import '../styles/productoDetalle.css';
import Banner from './banner';
import Carrito from './carrito';
import SwiperHome from './swiperHome';
import { motion } from 'framer-motion';
import HeaderMobileHome from './headerMobileHome';
import HeaderMobileDetail from './headerMobileDetail';
import LoadingScreen from './loadingScreen';

function ProductoDetalle() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [producto, setProducto] = useState(null);
  const [productos, setProductos] = useState([]);
  const [productosSimilares, setProductosSimilares] = useState([]);
  const [selecciones, setSelecciones] = useState([]);
  const { agregarAlCarrito } = useCarrito();
  const [activeCartShow, setActiveCartShow] = useState(false);
  const [bannerSuccess, setBannerSuccess] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(
    'Todos los artículos',
  );

  const [colorSeleccionado, setColorSeleccionado] = useState(null);
  const [tallaSeleccionada, setTallaSeleccionada] = useState(null);
  const [cantidad, setCantidad] = useState(1);
  const [imagenPrincipal, setImagenPrincipal] = useState('');
  const thumbnailsRef = useRef(null);
  const [activeAside, setActiveAside] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showBanner, setShowBanner] = useState(false);
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const [videoSrc, setVideoSrc] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [loading, setLoading] = useState(true);



  const handleOpenVideoModal = (videoSrc) => {
    setVideoSrc(videoSrc);
    setIsVideoModalOpen(true);
  };

  const handleCloseVideoModal = () => {
    setIsVideoModalOpen(false);
  };

  const handleImageClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const seleccionarColor = (color) => setColorSeleccionado(color);
  const seleccionarTalla = (talla) => setTallaSeleccionada(talla);

  const incrementarCantidad = () => setCantidad(cantidad + 1);
  const decrementarCantidad = () =>
    setCantidad(cantidad > 1 ? cantidad - 1 : 1);

  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const fetchProductos = async () => {
      try {
        const response = await axios.get(
          'https://ecommerce-backend-phi-seven.vercel.app/productos',
        );
        setProductos(response.data);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchProductos();
  }, []);

  useEffect(() => {
    const fetchProducto = async () => {
      try {
        const response = await axios.get(
          `https://ecommerce-backend-phi-seven.vercel.app/productos/${id}`,
        );
        console.log('Producto recibido:', response.data);
        setProducto(response.data);
        if (response.data.imagenes.length > 0) {
          setImagenPrincipal(response.data.imagenes[0]);
          setLoading(false);
        }
      } catch (error) {
        console.error('Error fetching product:', error);
        setLoading(true);
      }
    };

    fetchProducto();
  }, [id]);

  const handleActiveCart = () => {
    setActiveCartShow(true);
  };

  const handleActiveAside = () => {
    setActiveAside(true);
  };

  const handleCloseAside = () => {
    setActiveAside(false);
  };

  useEffect(() => {
    if (producto && productos.length > 0) {
      const primerasTresLetras = producto.nombre.trim().substring(0, 3).toUpperCase();
      const similares = productos.filter(
        (prod) => 
          prod.nombre.trim().substring(0, 3).toUpperCase() === primerasTresLetras &&
          prod.id !== producto.id
      );
      setProductosSimilares(similares);
    }
  }, [producto, productos]);
  
  

  const handleAgregarAlCarrito = () => {
    if (colorSeleccionado || tallaSeleccionada) {
      const productoSeleccionado = {
        ...producto,
        color: colorSeleccionado,
        talle: tallaSeleccionada,
        cantidad,
      };
      agregarAlCarrito(productoSeleccionado);
      handleShowBanner();
    } else {
      alert('Por favor, seleccione color y talla.');
    }
  };

  const handleSeleccionChange = (index, field, value) => {
    const updatedSelecciones = [...selecciones];
    updatedSelecciones[index] = {
      ...updatedSelecciones[index],
      [field]: value,
    };
    setSelecciones(updatedSelecciones);
  };

  const handleAddSelection = () => {
    setSelecciones([
      ...selecciones,
      {
        color: '',
        talle: '',
        cantidad: 1,
        id: producto.id, 
      },
    ]);
  };

  const handleRemoveSelection = (index) => {
    setSelecciones(selecciones.filter((_, i) => i !== index));
  };

  const handleCategoryClick = (category) => {
    navigate(`/productos/categoria/${category}`);
    setSelectedCategory(category);
    setSearchTerm('');
  };

  const onProductoClick = (productoId) => {
    navigate(`/producto/${productoId}`);
  };

  const handleCloseCart = () => {
    setActiveCartShow(false);
  };

  const handleShowBanner = () => {
    setBannerSuccess(true);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(window.location.href);
      setShowBanner(true);
    } catch (err) {
      console.error('Error al copiar la URL: ', err);
    }
  };

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: 'Compartir este enlace',
          text: 'Mira este sitio web genial!',
          url: window.location.href,
        });
      } catch (err) {
        console.error('Error al compartir: ', err);
      }
    } else {
      alert('La API de compartir no está disponible en este navegador.');
    }
  };

  useEffect(() => {
    let timeout;
    if (bannerSuccess) {
      timeout = setTimeout(() => {
        setBannerSuccess(false);
      }, 3000);
    } else {
      setBannerSuccess(false);
    }

    return () => clearTimeout(timeout);
  }, [bannerSuccess]);

  useEffect(() => {
    let timeout;
    if (showBanner) {
      timeout = setTimeout(() => {
        setShowBanner(false);
      }, 3000);
    } else {
      setShowBanner(false);
    }

    return () => clearTimeout(timeout);
  }, [showBanner]);

  useEffect(() => {
    const container = thumbnailsRef.current;

    if (container) {
      const handleMouseDown = (e) => {
        container.isDragging = true;
        container.startX = e.pageX - container.offsetLeft;
        container.scrollLeft = container.scrollLeft;
      };

      const handleMouseMove = (e) => {
        if (!container.isDragging) return;
        e.preventDefault();
        const x = e.pageX - container.offsetLeft;
        const walk = (x - container.startX) * 2;
        container.scrollLeft = container.scrollLeft - walk;
      };

      const handleMouseUp = () => {
        container.isDragging = false;
      };

      container.addEventListener('mousedown', handleMouseDown);
      container.addEventListener('mousemove', handleMouseMove);
      container.addEventListener('mouseup', handleMouseUp);
      container.addEventListener('mouseleave', handleMouseUp);

      return () => {
        container.removeEventListener('mousedown', handleMouseDown);
        container.removeEventListener('mousemove', handleMouseMove);
        container.removeEventListener('mouseup', handleMouseUp);
        container.removeEventListener('mouseleave', handleMouseUp);
      };
    }
  }, [producto]);

  if (loading) {
    return <LoadingScreen />; 
  }
  
  if (!producto) {
    return null;
  }

  return (
    <Fragment>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.15 }}
      >
        <Banner banner={bannerSuccess} title="Producto agregado" />
        <Banner banner={showBanner} title="Enlace Copiado" />
        <Carrito
          activeCart={activeCartShow}
          handleCloseCart={handleCloseCart}
        />

        <div className="container-web-viewport">
          <div className="header-top-banner-products">
            <h1 className="text-inside-banner-products">
              El envío es gratis a partir de tu compra de 12 unidades en
              artículos
            </h1>
          </div>

          <header className="header-general-products">
            <div className="profile-brand">
              <img
                className="img-ptofile-brand"
                onClick={() => navigate('/')}
                src={require('../images/logo.png')}
                alt="logo"
              />
            </div>

            <input
              className="input-header-search-products"
              type="text"
              placeholder="Buscar productos"
              value={searchTerm}
              onChange={handleSearchChange}
            />

            <div className="container-row-header-options">
              <li
                className={`option-header ${
                  selectedCategory === 'Todos los artículos'
                    ? 'selected-tab-pr'
                    : ''
                }`}
                onClick={() => handleCategoryClick('Todos los artículos')}
              >
                Todos
              </li>
              <li
                className={`option-header option-span-mobile ${
                  selectedCategory === 'Buzos' ? 'selected-tab-pr' : ''
                }`}
                onClick={() => handleCategoryClick('Buzos')}
              >
                Buzos
              </li>
              <li
                className={`option-header option-span-mobile ${
                  selectedCategory === 'Camperas' ? 'selected-tab-pr' : ''
                }`}
                onClick={() => handleCategoryClick('Camperas')}
              >
                Camperas
              </li>
              <li
                className={`option-header option-span-mobile ${
                  selectedCategory === 'Remeras' ? 'selected-tab-pr' : ''
                }`}
                onClick={() => handleCategoryClick('Remeras')}
              >
                Remeras
              </li>
              <li
                className={`option-header option-span-mobile ${
                  selectedCategory === 'Zapatillas' ? 'selected-tab-pr' : ''
                }`}
                onClick={() => handleCategoryClick('Zapatillas')}
              >
                Zapatillas
              </li>
            </div>

            <div className="card-cart-products" onClick={handleActiveCart}>
              <img
                className="img-card-cart-products"
                src={require('../images/cart.png')}
                alt="cart"
              />
            </div>
          </header>

          <div className="container-fluid">
            <div className="row">
              <div className="col-md-4 col-lg-2 col-xl-2 span-tab-products">
                <div className="tab-left-products-land">
                  <h1 className="title-tab-left">Categorías</h1>
                  <li
                    className={`item-select-tab ${
                      selectedCategory === 'Todos los artículos'
                        ? 'selected-tab-pr'
                        : ''
                    }`}
                    onClick={() => handleCategoryClick('Todos los artículos')}
                  >
                    Todos
                  </li>
                  <li
                    className={`item-select-tab ${
                      selectedCategory === 'Buzos' ? 'selected-tab-pr' : ''
                    }`}
                    onClick={() => handleCategoryClick('Buzos')}
                  >
                    Buzos
                  </li>
                  <li
                    className={`item-select-tab ${
                      selectedCategory === 'Camperas' ? 'selected-tab-pr' : ''
                    }`}
                    onClick={() => handleCategoryClick('Camperas')}
                  >
                    Camperas
                  </li>
                  <li
                    className={`item-select-tab ${
                      selectedCategory === 'Remeras' ? 'selected-tab-pr' : ''
                    }`}
                    onClick={() => handleCategoryClick('Remeras')}
                  >
                    Remeras
                  </li>
                  <li
                    className={`item-select-tab ${
                      selectedCategory === 'Zapatillas' ? 'selected-tab-pr' : ''
                    }`}
                    onClick={() => handleCategoryClick('Zapatillas')}
                  >
                    Zapatillas
                  </li>
                </div>
              </div>
              <div className="col-md-8 col-lg-10 col-xl-10 span-canvas-products">
                <div className="container">
                  <div className="row">
                    <div className="col-md-12 col-lg-6 span-detalle-pr">
                      <div className="container-img-detalle">
                        <h2 className="banner-tag-container-img-pr-web">
                          Artículo destacado
                        </h2>

                        <img
                          className="img-detalle-producto"
                          src={imagenPrincipal}
                          alt={producto.nombre}
                        />
                        <div
                          className="container-thumbnails"
                          ref={thumbnailsRef}
                        >
                          {producto.imagenes.map((imagen, index) => (
                            <img
                              key={index}
                              src={imagen}
                              alt={`Miniatura ${index + 1}`}
                              className="thumbnail"
                              onClick={() => setImagenPrincipal(imagen)}
                            />
                          ))}
                          {producto.video && (
                            <div className="thumbnail-video-container">
                              <video
                                className="thumbnail-video"
                                onClick={() => handleOpenVideoModal(producto.video)}
                                muted
                                autoPlay
                              >
                                <source src={producto.video} type="video/mp4" />
                                Tu navegador no soporta el elemento de video.
                              </video>
                            </div>
                          )}
                        </div>

                          
                      {isVideoModalOpen && (
                        <div className="video-modal" onClick={handleCloseVideoModal}>
                          <div
                            className="video-modal-content"
                            onClick={(e) => e.stopPropagation()}
                          >
                            <button className="close-modal" onClick={handleCloseVideoModal}>
                              Cerrar
                            </button>
                            <video className="expanded-video" controls autoPlay>
                              <source src={videoSrc} type="video/mp4" />
                              Tu navegador no soporta el elemento de video.
                            </video>
                          </div>
                        </div>
                      )}

                        <div
                          className="card-cart-products span-item-card-product span-detalle-pr-img"
                          onClick={() => navigate('/productos')}
                        >
                          <img
                            className="img-card-cart-products"
                            src={require('../images/atras.png')}
                            alt="back"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 col-lg-6 span-detalle-pr spanquery">
                      <div
                        className="card-cart-products span-item-card-product span-item-detalle-pr"
                        onClick={handleAgregarAlCarrito}
                      >
                        <img
                          className="img-card-cart-products"
                          src={require('../images/cart.png')}
                          alt="cart"
                        />
                      </div>
                      <h1>{producto.nombre}</h1>
                      <p className="price-detalle-pr">
                        Precio: ${producto.precio.toLocaleString()}
                      </p>

                      <div className="container-flex-art">
                        <div className="colores-container">
                          <h3>Colores</h3>
                          <select
                            value={colorSeleccionado}
                            className="drop-detalle-pr"
                            onChange={(e) => seleccionarColor(e.target.value)}
                          >
                            <option value="">Colores</option>
                            {producto.colores.map((colorObj, index) => (
                              <option
                                className="item-drop"
                                key={index}
                                value={colorObj.color}
                              >
                                <li className='b'>
                                {colorObj.color.toUpperCase()} (Stock: {colorObj.stock})
                                </li>
                              </option>
                            ))}
                          </select>
                        </div>

                        {producto.talles && producto.talles.some(talla => talla.talle && talla.stock) && (
                        <div className="tallas-container">
                          <h3>Talles</h3>
                          <select
                            value={tallaSeleccionada}
                            className="drop-detalle-pr"
                            onChange={(e) => seleccionarTalla(e.target.value)}
                          >
                            <option value="">Talles</option>
                            {producto.talles.map((tallaObj, index) => (
                              <option key={index} value={tallaObj.talle}>
                                {tallaObj.talle.toUpperCase()} (Stock:{' '}
                                {tallaObj.stock})
                              </option>
                            ))}
                          </select>
                        </div>
                        )}

                        <div className="cantidad-container">
                          <h4>Cantidad</h4>
                          <div className="cantidad-control">
                            <button
                              className="btn-add-qt"
                              onClick={decrementarCantidad}
                            >
                              -
                            </button>
                            <span>{cantidad}</span>
                            <button
                              className="btn-add-qt"
                              onClick={incrementarCantidad}
                            >
                              +
                            </button>
                          </div>
                        </div>
                      </div>

                      <p className="text-description-detalle-pr">
                        {producto.descripcion}
                      </p>

                            <button className='btn-shop-pr-dt'  onClick={handleAgregarAlCarrito}>Agregar al carrito</button>

                    </div>
                  </div>
                </div>

                <h1 className="title-pr-bottom-plus">Productos Relacionados</h1>

                <div className="container section-swiper-pr">
                  <SwiperHome
                    products={productosSimilares}
                    onProductoClick={onProductoClick}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-mobile-viewport container-detail">
          <div className="header-mobile-home-detail">
            <HeaderMobileDetail
              handleActiveCart={handleActiveCart}
              activeAside={activeAside}
              handleActiveAside={handleActiveAside}
              handleCloseAside={handleCloseAside}
            />
          </div>

          <div className="container-img-detail">
            <div className="container-items-share">
              <div>
                <img
                  className="img-items-share"
                  src={require('../images/enviar.png')}
                  onClick={handleShare}
                  alt="e"
                />
              </div>
              <div>
                <img
                  className="img-items-share"
                  src={require('../images/copiar.png')}
                  onClick={handleCopy}
                  alt="e"
                />
              </div>
            </div>
            <h2 className="item-dect-pr-detail">Destacado</h2>
            <div className='container-items-auto-img-dt-pr'>
              <img className='img-items-auto-img-dt-pr' src={require('../images/star.png')} alt="" />
              <p className='text-items-auto-img-dt-pr'>4.9</p>
            </div>
            <img className="img-detail" src={imagenPrincipal} alt="c" />

            <div className="container-thumbnails" onClick={handleImageClick}>
              {producto.imagenes.map((imagen, index) => (
                <img
                  key={index}
                  src={imagen}
                  alt={`Miniatura ${index + 1}`}
                  className="thumbnail"
                  onClick={() => setImagenPrincipal(imagen)}
                />
              ))}
              {producto.video && (
                <div
                  className="thumbnail-video-container"
                  onClick={() => handleOpenVideoModal(producto.video)}
                >
                  <video className="thumbnail-video" autoPlay muted loop>
                    <source src={producto.video} type="video/mp4" />
                    Tu navegador no soporta el elemento de video.
                  </video>
                </div>
              )}
            </div>
          </div>

          {isModalOpen && (
            <div className="modal-overlay" onClick={handleCloseModal}>
              <div className="modal-content">
                <img
                  src={imagenPrincipal}
                  alt={producto.nombre}
                  className="modal-image"
                />
                <button className="close-button" onClick={handleCloseModal}>
                  Cerrar
                </button>
              </div>
            </div>
          )}

          {isVideoModalOpen && (
            <div className="video-modal" onClick={handleCloseVideoModal}>
              <div
                className="video-modal-content"
                onClick={(e) => e.stopPropagation()}
              >
                <button className="close-modal" onClick={handleCloseVideoModal}>
                  Cerrar
                </button>
                <video className="expanded-video" controls autoPlay>
                  <source src={videoSrc} type="video/mp4" />
                  Tu navegador no soporta el elemento de video.
                </video>
              </div>
            </div>
          )}

          <div className="container container-items-scroll">
            <div className="container-title-art">
              <h1 className="title-detail-art"> {producto.nombre} </h1>
            </div>

            <div className="container-flex-art">
              <div className="colores-container">
                <h3>Colores</h3>
                <div className="colores">
                  {producto.colores.map((colorObj, index) => (
                    <div
                      key={index}
                      className={`color-item ${
                        colorObj.color === colorSeleccionado
                          ? 'selected-color'
                          : ''
                      }`}
                      onClick={() => seleccionarColor(colorObj.color)}
                    >
                      {colorObj.color.toUpperCase()} (Stock: {colorObj.stock})
                    </div>
                  ))}
                </div>
              </div>

              {producto.talles && producto.talles.some(talla => talla.talle && talla.stock) && (    
              <div className="tallas-container">
                <h3>Talles</h3>
                <div className="tallas">
                  {producto.talles.map((tallaObj, index) => (
                    <div
                      key={index}
                      className={`talla-item ${
                        tallaObj.talle === tallaSeleccionada
                          ? 'selected-size'
                          : ''
                      }`}
                      onClick={() => seleccionarTalla(tallaObj.talle)}
                    >
                      {tallaObj.talle.toUpperCase()} (Stock: {tallaObj.stock})
                    </div>
                  ))}
                </div>
              </div>
              )}

              <div className="cantidad-container cantidad-container-mobile">
                <div className="cantidad-control">
                  <button className="btn-add-qt" onClick={decrementarCantidad}>
                    -
                  </button>
                  <span>{cantidad}</span>
                  <button className="btn-add-qt" onClick={incrementarCantidad}>
                    +
                  </button>
                </div>
              </div>
            </div>

            <div className="container-description-art">
              <h4>Descripción</h4>
              <p className="text-description-detalle-pr">
                {producto.descripcion}
              </p>
            </div>

            <h1 className="title-pr-bottom-plus">Productos Relacionados</h1>

            <div className="section-swiper-pr">
              <SwiperHome
                products={productosSimilares}
                onProductoClick={onProductoClick}
              />
            </div>

            <div className="container-items-price-cart">
              <div>
                <h6 className="price-detail">
                  ${producto.precio.toLocaleString()}{' '}
                </h6>
              </div>
              <div>
                <button
                  className="cart-btn-detail"
                  onClick={handleAgregarAlCarrito}
                >
                  Agregar
                </button>
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </Fragment>
  );
}

export default ProductoDetalle;
