import React, { Fragment, useEffect, useState } from 'react';
import axios from 'axios';
import { Button } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import BannerDash from './bannerDash';
import { motion } from 'framer-motion';

function SuscripcionesComp({ formattedDate }) {
  const [suscripciones, setSuscripciones] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const fetchSuscripciones = async () => {
      try {
        const response = await axios.get(
          'https://ecommerce-backend-phi-seven.vercel.app/subscripciones',
        );
        setSuscripciones(response.data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchSuscripciones();
  }, []);

  const filteredSuscripciones = suscripciones.filter((subs) => {
    const allFields = [
      subs.nombre,
      subs.apellido,
      subs.email,
    ].join(' ').toLowerCase();

    return allFields.includes(searchTerm.toLowerCase());
  });

  return (
    <Fragment>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.15 }}
      >
        <div className="container-web-viewport">
          <div>
            <BannerDash
              title="Registro de suscripciones"
              formattedDate={formattedDate}
              text="Dashboard"
            />
          </div>

          <div className='container-fluid'>
            <div className='row'>
              <div className='col-xl-4 col-lg-4 col-md-6'>
                <div className='card-col-ctn'>
                  <p className='text-card-col-ctn'>Suscripciones</p>
                  <h6 className='text-ctn-total-col'>
                    {suscripciones.length}
                  </h6>
                </div>
              </div>
            </div>
          </div>

          <div className="container-table">
            <div className="header-actions">
             
            </div>

            <table className="table">
              <thead>
                <tr>
                  <th>Nombre</th>
                  <th>Apellido</th>
                  <th>Email</th>
                </tr>
              </thead>
              <tbody>
                {loading
                  ? Array(10)
                      .fill()
                      .map((_, index) => (
                        <tr key={index}>
                          <td>
                            <Skeleton width={100} />
                          </td>
                          <td>
                            <Skeleton width={100} />
                          </td>
                          <td>
                            <Skeleton width={200} />
                          </td>
                        </tr>
                      ))
                  : filteredSuscripciones.map((subs) => (
                      <tr key={subs.id}>
                        <td>{subs.nombre}</td>
                        <td>{subs.apellido}</td>
                        <td>{subs.email}</td>
                      </tr>
                    ))}
              </tbody>
            </table>
          </div>

          {error && <p>Error: {error}</p>}
        </div>
      </motion.div>
    </Fragment>
  );
}

export default SuscripcionesComp;
